import $http from '@/utils/http.js';

/**
 * 四大权益数据
 * @param data
 */
export function getFourInterestsData(data) {
    return $http.get('/Content/rights_interests_index', data, 'loadingDiv');
}

/**
 * 入四大权益保存修改操作
 * @param data
 */
export function inFourInterestsSaveModifyAction(data) {
    return $http.post('/Content/rights_interests_save', data, 'loadingDiv', 'multipart/form-data;charset=utf-8');
}
