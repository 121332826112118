<template>
    <div class="four-interests-page">
        <el-form class="four-interests-form" label-width="100px" label-position="left"
            v-for="(item, index) in fourInterestsData.fourInterestsForm" :key="`${item.id}_${index}`"
            :model="fourInterestsData" :ref="item.formRef">
            <div class="content-wrap" style="background-color: #fff; margin: 20px; border-radius: 10px">
                <el-row>
                    <el-col :span="12">
                        <div class="content-left">
                            <el-form-item :prop="`fourInterestsForm[${index}].title`" :rules="[
                                {
                                    required: true,
                                    message: `主标题${index + 1}不能为空`,
                                    trigger: 'blur'
                                }
                            ]">
                                <div>主标题{{ index + 1 }}：</div>
                                <el-input size="small" style="width: 500px" maxlength="16" v-model="item.title"
                                    :placeholder="`请输入主标题${index + 1}内容`" v-if="item.isProcessModify"></el-input>
                                <div style="width: 500px" v-else>{{ item.title }}</div>
                            </el-form-item>
                            <el-form-item :prop="`fourInterestsForm[${index}].subtitle`" :rules="[
                                {
                                    required: true,
                                    message: `副标题${index + 1}不能为空`,
                                    trigger: 'blur'
                                }
                            ]">
                                <div>副标题{{ index + 1 }}：</div>
                                <el-input size="small" style="width: 500px" maxlength="100" v-model="item.subtitle"
                                    :placeholder="`请输入副标题${index + 1}内容`" v-if="item.isProcessModify"></el-input>
                                <div style="width: 500px" v-else>{{ item.subtitle }}</div>
                            </el-form-item>
                        </div>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :prop="`fourInterestsForm[${index}].photo`" :rules="[
                            {
                                required: true,
                                message: '上传图片不能为空',
                                trigger: 'change'
                            }
                        ]">
                            <div>上传图片：</div>
                            <el-upload class="avatar-uploader" :ref="`uploadInterestsPhoto${index}`" action="#"
                                :limit="1" accept=".png,.jpg" :auto-upload="false" :show-file-list="false"
                                :on-change="handleInterestsPhotoSuccess.bind(null, { index: index, data: item })">
                                <img v-if="item.photo" :src="item.photo" class="avatar" />
                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                            </el-upload>
                            <div class="picture-desc">
                                <p>尺寸为72*72px</p>
                                <p>格式为PNG/JPG，大小不超过10MB</p>
                            </div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <div class="content-right" @click="handleSaveProcessBtn(item)" v-if="item.isProcessModify">
                            <el-button type="text">保存</el-button>
                        </div>
                        <div class="content-right" @click="handleModifyProcessBtn(item)" v-else>
                            <i class="el-icon-edit-outline size-icon"></i>
                            修改
                        </div>
                    </el-col>
                </el-row>
            </div>
        </el-form>
    </div>
</template>

<script>
import jsonTool from '@/utils/jsonTool.js';
import { getFourInterestsData, inFourInterestsSaveModifyAction } from '@/common/api/contentManage/fourInterests.js';
export default {
    data() {
        return {
            fourInterestsData: {
                fourInterestsForm: [
                    {
                        id: '',
                        // 主标题1
                        title: '',
                        // 副标题1
                        subtitle: '',
                        // 标题1图片
                        photo: '',
                        isProcessModify: false,
                        // 上传图片保存数据
                        fileList: []
                    },
                    {
                        id: '',
                        // 主标题2
                        title: '',
                        // 副标题2
                        subtitle: '',
                        // 标题2图片
                        photo: '',
                        isProcessModify: false,
                        // 上传图片保存数据
                        fileList: []
                    },
                    {
                        id: '',
                        // 主标题3
                        title: '',
                        // 副标题3
                        subtitle: '',
                        // 标题3图片
                        photo: '',
                        isProcessModify: false,
                        // 上传图片保存数据
                        fileList: []
                    },
                    {
                        id: '',
                        // 主标题4
                        title: '',
                        // 副标题4
                        subtitle: '',
                        // 标题4图片
                        photo: '',
                        isProcessModify: false,
                        // 上传图片保存数据
                        fileList: []
                    }
                ]
            }
        };
    },
    created() {
        this.queryFourInterestsData();
    },
    methods: {
        queryFourInterestsData() {
            getFourInterestsData()
                .then(res => {
                    let { code, result } = res;
                    if (code === 200) {
                        let newResult = jsonTool(result);
                        this.fourInterestsData.fourInterestsForm = newResult.map(item => {
                            return {
                                id: item.id,
                                title: item.mainTitle,
                                subtitle: item.subTitle,
                                photo: item.picture,
                                isProcessModify: item.isProcessModify,
                                fileList: [],
                                formRef: 'formref' + item.id
                            };
                        });
                    }
                })
                .catch(err => {
                    // console.log(err);
                });
        },
        // 处理图片上传
        handleInterestsPhotoSuccess(obj, file, fileList) {
            let index = obj.index;
            const isType = file.type === 'image/jpeg' || 'image/png';
            const isLt10M = file.size / 1024 / 1024 < 10;
            if (!isType) {
                this.$message.error('分类照片只能是 jpg/png 格式!');
                return;
            } else if (!isLt10M) {
                this.$message.error('上传图片大小不超过10MB');
                return;
            } else {
                this.checkImgSize(file).then(data => {
                    if (data) {
                        this.fourInterestsData.fourInterestsForm[index].photo = URL.createObjectURL(file.raw);
                        this.fourInterestsData.fourInterestsForm[index].fileList = file.raw;
                    }
                });
            }
            this.$refs[`uploadInterestsPhoto${index}`].clearFiles();
        },
        checkImgSize(file) {
            return new Promise(function (resolve, reject) {
                let width = 72;
                let height = 72;
                let _URL = window.URL || window.webkitURL;
                let img = new Image();
                img.onload = function () {
                    let valid = img.width === width && img.height === height;
                    valid ? resolve() : reject();
                };
                img.src = _URL.createObjectURL(file.raw);
            }).then(
                () => {
                    return file.raw;
                },
                () => {
                    this.$message.warning('上传图片尺寸只能是 72*72px !');
                    // return Promise.reject();
                }
            );
        },
        // 处理修改按钮
        handleModifyProcessBtn(item) {
            item.isProcessModify = true;
        },
        // 处理保存按钮
        handleSaveProcessBtn(item) {
            let ref = item.formRef;
            this.$refs[ref].validate(valid => {
                if (valid) {
                    let formData = new FormData();
                    formData.append('id', item.id);
                    formData.append('main_title', item.title);
                    formData.append('sub_title', item.subtitle);
                    formData.append('picture', item.fileList);
                    formData.append('sort', item.id);
                    // 打印formdata，不能直接用console.log打印
                    // formData.forEach((value, key) => {
                    //     console.log('key %s: value %s', key, value);
                    // });
                    inFourInterestsSaveModifyAction(formData)
                        .then(res => {
                            let { code, msg } = res;
                            if (code === 200) {
                                this.$message({
                                    type: 'success',
                                    message: `${msg}`,
                                    center: true
                                });
                                this.queryFourInterestsData();
                            }
                        })
                        .catch(err => {
                            // console.log(err);
                        });
                }
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.four-interests-page {
    box-sizing: border-box;
    // height: 100%;
    margin: 0 -20px 20px;
    background-color: #f7f8fa;

    .content-wrap {
        &::after {
            clear: both;
            display: block;
            content: '';
            height: 0;
            visibility: hidden;
        }

        :deep(.el-form-item__content) {
            margin: 0 0 0 15px !important;
        }

        .avatar-uploader :deep(.el-upload) {
            border: 1px dashed #d9d9d9;
            border-radius: 6px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
        }

        .avatar-uploader .el-upload:hover {
            border-color: #409eff;
        }

        .avatar-uploader-icon {
            font-size: 28px;
            color: #8c939d;
            width: 90px;
            height: 90px;
            line-height: 90px;
            text-align: center;
        }

        .avatar {
            width: 90px;
            height: 90px;
            display: block;
        }

        .avatar-uploader {
            position: relative;
        }

        .picture-desc {
            position: absolute;
            left: 28%;
            bottom: 2px;
        }

        .content-left {
            :deep(.el-input) {
                display: block;
                overflow: hidden;
                word-break: keep-all;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }

        .content-right {
            float: right;
            margin: 15px 15px 0 0;
            cursor: pointer;

            .size-icon {
                position: relative;
                top: 3px;
                font-size: 24px;
            }
        }
    }
}
</style>
